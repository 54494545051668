<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <FormRow>
          <template v-slot:label>カテゴリーを選択</template>
          <template v-slot:labelNote>
            <Tooltip tooltipMsg="カテゴリーを選択するとメニューの並べ替えができます"
          /></template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-select">
                <select
                  class="form-control form-select-input"
                  name="category"
                  v-model="searchForm.category"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </template>
        </FormRow>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <table class="dataTable">
                    <thead>
                      <tr>
                        <th class="dataTable-fix">
                          <label class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :checked="isAllChecked"
                              :disabled="isNoData ? true : false"
                              @click="checkAll"
                            />
                            <span class="form-check-label"></span>
                          </label>
                        </th>
                        <th class="dataTable-fix">サムネイル</th>
                        <th>メニュー名</th>
                        <th>料金表示</th>
                        <th>カテゴリー</th>
                        <th class="dataTable-fix"></th>
                        <th
                          v-if="searchForm.category"
                          class="dataTable-fix"
                        ></th>
                      </tr>
                    </thead>
                    <Draggable
                      :list="lists"
                      @change="handleSort"
                      tag="tbody"
                      :animation="300"
                      handle=".sortable-handle"
                      :disabled="draggableDisabled"
                    >
                      <tr v-for="item in lists" :key="item.id">
                        <td>
                          <label class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :value="item.id"
                              v-model="checkedItems"
                              @change="check"
                            />
                            <span class="form-check-label"></span>
                          </label>
                        </td>
                        <td>
                          <div class="thumbnail">
                            <img
                              v-if="item.image.url"
                              class="thumbnail-img"
                              :src="item.image.url"
                            />
                          </div>
                        </td>
                        <td>{{ item.name }}</td>
                        <td>
                          <span v-if="item.cost"
                            >{{ item.cost | comma }}円</span
                          >
                          <span v-else>-</span>
                        </td>
                        <td>{{ item.category.name }}</td>
                        <td>
                          <router-link
                            class="btn btn-sm btn-bd-main"
                            :to="{
                              name: 'ShopMenuEdit',
                              params: { id: item.id },
                            }"
                            >編集</router-link
                          >
                        </td>
                        <td v-if="searchForm.category">
                          <i class="aikon aikon-handle sortable-handle"></i>
                        </td>
                      </tr>
                      <tr v-if="isNoData" class="noData">
                        <td colspan="999">{{ noDataMessage }}</td>
                      </tr>
                    </Draggable>
                  </table>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  modulePath="menu/getMenus"
                  :subdomain="subdomain"
                  :shopId="shopId"
                  :listLength="count"
                  :searchConditions="searchConditions"
                  ref="pagination"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <Modal @close="closeModal()" v-if="modal">
        <template v-slot:headline>削除</template>
        <template v-slot:body>
          <p class="description text-align-center">
            選択したメニューを削除しますか？
          </p>
          <ul class="listGrid justify-content-center">
            <li class="listGrid-item">
              <button
                class="btn btn-lg btn-white"
                type="button"
                @click="closeModal()"
              >
                キャンセル
              </button>
            </li>
            <li class="listGrid-item">
              <ActionButton
                class="btn btn-lg btn-red"
                :handle-submit="deleteMenu"
                button-text="削除する"
              />
            </li>
          </ul>
        </template>
      </Modal>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-red"
              :handle-submit="handleDeleted"
              :disabled="!isCheckedItemsExisted"
              button-text="削除する"
            />
          </li>
          <li class="listGrid-item pos-end">
            <router-link class="btn btn-main" :to="{ name: 'ShopMenuRegister' }"
              >新規登録</router-link
            >
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import lineClamp from 'vue-line-clamp';
import Draggable from 'vuedraggable';
import nav from '@/mixins/nav/menu';
import checkall from '@/mixins/plugin/checkall';
import search from '@/mixins/plugin/search';
import modal from '@/mixins/plugin/modal';
import { COMMON_MESSAGE } from '@/config/message';
//component
import Tooltip from '@/components/Tooltip.vue';
import FormRow from '@/components/FormRow.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import Modal from '@/components/Modal.vue';

Vue.use(lineClamp, {
  importCss: true,
});

export default {
  name: 'ShopGalleryPhotoList',
  data: function() {
    return {
      pageName: 'メニュー',
      lines: 3,
      draggableDisabled: true,
      searchConditions: {},
      storeModule: 'menu',
      searchFields: ['category'],
      isFirstLoaded: true,
      deletedModal: false,
    };
  },
  components: {
    Draggable,
    Tooltip,
    FormRow,
    PaginationLog,
    Modal,
  },
  mixins: [nav, checkall, search, modal],
  computed: {
    ...mapGetters({
      lists: 'menu/menuList',
      categories: 'menu/categoryList',
      userInfo: 'auth/infor',
      count: 'menu/menuCount',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
    isNoData() {
      return this.lists.length === 0;
    },
    noDataMessage() {
      return COMMON_MESSAGE.noData;
    },
    isCheckedItemsExisted() {
      return this.isAllChecked || this.checkedItems.length > 0;
    }
  },
  watch: {
    'searchForm.category': function() {
      if (this.isFirstLoaded) {
        this.isFirstLoaded = false;
      } else {
        this.categorySelect();
      }
    },
    lists() {
      if (this.isAllChecked) {
        for (const item of this.lists) {
          if (!this.checkedItems.includes(item.id)) {
            this.checkedItems.push(item.id);
          }
          if (this.removedItems.includes(item.id)) {
            this.checkedItems = this.checkedItems.filter((id) => id !== item.id);
          }
        }
      }
    },
  },
  methods: {
    async handleSort(value) {
      this.$message.reset();
      let newIndexList = [];
      this.lists.forEach((el) => {
        newIndexList.push(el.ord);
      });
      let index;
      if (value.moved.newIndex < value.moved.oldIndex)
        index = value.moved.newIndex + 1;
      else index = value.moved.newIndex - 1;
      const data = {
        id: value.moved.element.id,
        sortOrder: newIndexList[index],
      };
      const result = await this.$store.dispatch('menu/sortMenus', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        categoryId: this.searchForm.category,
        data: data,
      });
      this.getMenus();
      if (result) {
        this.$message.updated('menu');
      }
    },
    categorySelect: function() {
      if (!this.searchForm.category) {
        this.resetSearch();
        this.draggableDisabled = true;
      } else {
        this.search();
        this.draggableDisabled = false;
      }
    },
    async deleteMenu() {
      const result = await this.$store.dispatch('menu/deleteMenu', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        data: {
          allFlag: this.isAllChecked,
          id: this.isAllChecked ? this.removedItems : this.checkedItems,
          searchCondition: this.searchForm,
        },
      });
      this.resetCheck();
      this.closeModal();
      if (result) {
        this.$refs.pagination.resetPagination();
        this.$message.deleted('menu');
      }
    },
    getMenus() {
      return this.$store.dispatch('menu/getMenus', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        category: this.searchForm.category,
      });
    },
    getCategories() {
      return this.$store.dispatch('menu/getCategories', {
        subdomain: this.subdomain,
        shopId: this.shopId,
      });
    },
    handleDeleted() {
      if (this.isCheckedItemsExisted) {
        this.showModal();
      } else {
        this.$message.noSelectedItem();
      }
    },
    resetCheck() {
      this.checkedItems = [];
      this.isAllChecked = false;
      this.removedItems = [];
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    await this.getCategories();
    this.$loading.clear(loading);
  },
};
</script>
